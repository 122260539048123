import React, { useState } from "react";
import Layout from "../components/layout";
import "../styles/pages/service-providers.scss";

const allCategories = [
  "electrician",
  "plumber",
  "ac-mechanic",
  "painter",
  "water-can-supplier",
];

const data = [
  {
    id: 1,
    name: "Sampath",
    mobile: "+919940565477",
    category: ["electrician", "plumber"],
    description: "Electrical and plumbing works",
  },
  {
    id: 2,
    name: "Mani Vannan",
    mobile: "+919500065724",
    category: ["ac-mechanic"],
    description: "AC service and installation",
  },
  {
    id: 3,
    name: "Rajan",
    mobile: "+918925745294",
    category: ["painter"],
    description: "Painting & Varnish",
  },
  {
    id: 4,
    name: "7 Star Enterprises",
    mobile: "+919879871234",
    category: ["water-can-supplier"],
    description: "Drinking water cans in your doorstep",
  },
  {
    id: 5,
    name: "Vijay Anand",
    mobile: "+919879870012",
    category: ["plumbing"],
    description: "Water tank cleaner",
  },
  {
    id: 6,
    name: "Vinoth Kumar",
    mobile: "+917519871234",
    category: ["electrician"],
    description: "Any electrical related problem",
  },
];

export default function ServiceProviders() {
  const [category, setCategory] = useState("all");
  const [contacts, setContacts] = useState(data);
  const handleCategory = (cat) => {
    setCategory(cat);
    if (cat === "all") {
      setContacts(data);
    } else {
      const dataToShow = data.filter((c) => c.category.includes(cat));
      setContacts(dataToShow);
    }
  };
  return (
    <Layout title="Service Providers in Our Area">
      <h1 className="page-title">Service Providers</h1>
      <div className="category-list">
        <ul>
          <li
            onClick={() => handleCategory("all")}
            className={category === "all" ? "active" : ""}
          >
            all
          </li>
          {allCategories.map((c) => {
            return (
              <li
                onClick={() => handleCategory(c)}
                className={category === c ? "active" : ""}
                key={c}
              >
                {c}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="data-list">
        {contacts.length === 0 && (
          <div className="no-data">
            <h3>Sorry!</h3>
            <p>No data found!</p>
          </div>
        )}
        <ul>
          {contacts.map((d) => {
            const avatarChar = d.name.substring(0, 1);
            return (
              <li>
                <div className="data-list-item">
                  <div className="avatar">{avatarChar}</div>
                  <div className="name">
                    <h3>{d.name}</h3>
                    {d.description && <p>{d.description}</p>}
                  </div>
                  <div className="category">
                    <ul>
                      {d.category.map((c) => {
                        return <li>{c}</li>;
                      })}
                    </ul>
                  </div>
                  <div className="action">
                    <a href={`tel:${d.mobile}`}>{d.mobile}</a>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="adding-new-data">
        <p>
          If you have any contact which will be useful for people in our area,
          kindly please send it to{" "}
          <a href={`https://wa.me/+918056277987`}>+91-80562-77987</a> via
          WhatsApp, we will get it updated here.
        </p>
      </div>
    </Layout>
  );
}
